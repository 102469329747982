@import "./colors.scss";

// Mixin to change all octogon size components at Once
// as they are all based on square size
@mixin octo_size($base_width){
  .octogon{
    max-width: calc(#{$base_width} * 3);
    margin: 0 auto;
    display: block;
    .square{
      width: $base_width;
      height: $base_width;
    }
    .flex {
      .square:nth-child(2n){
        z-index: 2;
      }
      .square:nth-child(0), .square:nth-child(2n+1){
        z-index: 1;
      }
      &:nth-child(1){
        height: 7.9vw;
      }
    }
    .triangle.r{ border-left: $base_width solid transparent; }
    .triangle.l{ border-right: $base_width solid transparent; }
    .fullrec {
      width: calc(3 * #{$base_width});
      height: $base_width;
    }

    // COLORS
    @each $color_name, $color_value in $colors{
      &.#{$color_name} {
        border-bottom-color: $color_value;
        &.flex:nth-child(1){ border-bottom: 2px solid $color_value; }
        .square, .fullrec { background-color: $color_value; }
        .triangle.b { border-bottom: $base_width solid $color_value; }
        .triangle.t { border-top: $base_width solid $color_value; }
      }
    }

    &.grey .flex .fullrec h1{
      font-size: 2.2vw;
      margin: 0.8em;
    }
    #bloc-link{
      color: $color_octogon_secondary;
      display: block;
      text-align: center;
    }
  }
  .octogon:nth-child(1) {
    margin-top: 0;
  }
  .octogon:nth-child(2n){
    margin-left: calc(#{$base_width} * 2 + 0.4em);
    margin-top: calc(-#{$base_width} + 0.8em);
  }
  .octogon:nth-child(2n + 3){
    margin-top: calc(-#{$base_width} + 0.8em);
  }

  @media screen and (prefers-color-scheme: dark) {
    $color_octogon_primary: #464646; //grey
    $color_octogon_secondary: #BBB; // white
    $color_octogon_secondary_light: #0F377F; // blue
    $color_octogon_ter: #520000; //red
    $colors: (
      'grey': #464646,
      'white': #BBB,
      'blue': #0F377F,
      'red': #520000
    );

    // COLORS
    .octogon{
      @each $color_name, $color_value in $colors{
        &.#{$color_name} {
          border-bottom-color: $color_value;
          &.flex:nth-child(1){ border-bottom: 2px solid $color_value; }
          .square, .fullrec { background-color: $color_value; }
          .triangle.b { border-bottom: $base_width solid $color_value; }
          .triangle.t { border-top: $base_width solid $color_value; }
        }
      }
    }
  }
}

@include octo_size(8vw);

.octo-wrapper{
  position: relative;
  display: block;
  padding: 1em 0 2em 0;
}

.square{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
  h2{
    text-align: center;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-size: 2vw;
  }
  a{
    font-size: 1.2vw;
    color: $black;
    line-height: 1;
    h3{
      text-decoration: none;
    }
    p{
      margin: 0;
    }
    img{
      max-width: 80px;
    }
  }
  a:hover{
    cursor: pointer;
  }
}
.triangle{
  width : 0;
  height : 0;
  z-index: 2;
}

@media screen and (prefers-color-scheme: dark) {
  .square{
    a{
      color: $white;
    }
  }
}

@media screen and (max-width: 767px) {
  @include octo_size(60px);

  main.container{ padding: 0; }
  .octogon{
    margin: 0;
    .fullrec:has(#bloc-link){
      height: 80px;
    }
  }

  .octogon.grey .flex .fullrec h1{
    font-size: 22px;
    margin: 0.8em 0;
  }

  .octo-wrapper{
    padding: 2em 0 1em 0;
  }
  .square {
    h2{ font-size: 18px; }
    a{
      font-size: 14px;
      img{
        max-width: 42px;
        margin-top: 0;
      }
      h3{
        line-height: 0.8em;
        font-size: 14px;
      }
    }
  }
  .octogon .flex:nth-child(1){
    border-bottom: none !important;
    height: auto !important;
  }
  .octogon .flex .square:nth-child(2n){
    z-index: 3;
  }
}

@media screen and (min-width: 1440px){
  @include octo_size(400px);

  .octogon:nth-child(2n) {
    margin-left: 250px;
    margin-top: 8px;
  }
  .octogon:nth-child(2n+3) {
    margin-top: 8px;
  }
  .octogon .square{
    width: 133px;
    height: 133px;
  }
  .octogon .fullrec {
    width: 400px;
    height: 133px;
  }
  .octogon .triangle.b {
    border-bottom-width: 133px !important;
  }
  .octogon .triangle.r {
    border-left-width: 133px !important;
  }
  .octogon .triangle.l {
    border-right-width: 133px !important;
  }
  .octogon .triangle.t {
    border-top-width: 133px !important;
  }
  .octogon.grey .fullrec h1{
    font-size: 40px;
  }
  .octogon .flex:nth-child(1){
    border-bottom: none !important;
    height: auto !important;
  }
}
