.post-wrapper{
  min-height: calc(100vh - 3em);
  h1{
    color: $color_octogon_secondary_light;
    margin-bottom: 0;
  }
  h2{
    color: $color_octogon_secondary_light;
    font-size: 1.8rem;
    text-shadow: 1px 1px 1px #fff;
  }
  h3{
    font-size: 1.4rem;
    text-decoration: underline;
  }
  a{
    color: $color_octogon_primary;
  }
  aside{
    h2{
      margin-bottom: 0.2em;
    }
  }
  small.tags{
    text-align: center;
    .tag{
      padding: 0.3em 0.5em 0.2em;
    }
  }
}

small.tags{
  display: inline-block;
  width: 100%;
  .tag{
    display: inline-block;
    margin: 0 0.1em;
    padding: 0.2em 0.3em 0.1em;
    background-color: $color_octogon_secondary_light;
    color: $color_octogon_secondary;
    border-radius: 0.8em;
  }
}
