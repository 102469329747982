$color_octogon_primary: #AAA; //grey
$color_octogon_secondary: #FFF; // white
$color_octogon_secondary_light: #3962AF; // blue
$color_octogon_ter: #A11919; //red
$colors: (
  'grey': #AAA,
  'white': #FFF,
  'blue': #3962AF,
  'red': #A11919
);
$black: #000;
$white: #FFF;
$grey_bg: #AAA;

.black{
  color: $black;
}

.grey-bg{
  background-color: $grey_bg;
}

.linkedin{
  color: #0077B5;
}

.twitter{
  color: #55acee;
}

.gitlab{
  color: #FC6D27;
}

.ruby{
  color: #DD4242;
}

.bluesky{
  color: #3586f7;
}
