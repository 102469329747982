@import "./octogon.scss";
@import "./posts.scss";

/* General style */
h1{
  font-size: 3.6rem;
  line-height: 1.25; margin-bottom: 0.5em}
h2{
  font-size: 2.8rem;
  line-height: 1.3
}
h3{
  font-size: 1.2rem;
  letter-spacing: -.08rem;
  line-height: 1.35;
  margin: 0;
}

body {
  background-color: #e6f0ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%230c3556' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  font-family: Eurostile, Arial, sans-serif;
}
.container{
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 2.0rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.row{
  flex-direction: row;
}

.flex{
  display: flex;
}

.text-wrapper{
  padding: 1em;
  background-color: $grey_bg;
  color: $black;
}
code{
  background-color: $black;
  color: $white;
  padding: 0.2em 1em;
  display: block;
}
.container ul{
  background-color: $white;
  padding: 1em;
  list-style: none;
}
/* *********** */
/*Custom styles*/
/* *********** */
h1{
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 1px #fff;
  color: #47739A;
}
h2{
  text-shadow: 1px 1px 1px #222;
}

a span:hover{
  transform: scale(1.3);
  transition: linear 0.3s;
}

@media screen and (min-width: 300px) and (max-width: 768px){
  h1{
    font-size: 2.6rem;
    line-height: 1.25; margin-bottom: 0.5em}
  h2{
    font-size: 1.8rem;
    line-height: 1.3
  }
  h3{
    font-size: 1rem;
  }
  .row{
    flex-direction: column;
  }
}

h3 a{
  color: #A11919;
}
.octo-wrapper + .container{
  .text-wrapper{
    h3 a{
      color: #47739A;
    }
  }
}

img{
  width: 3em;
}

// ///////////////
// // Dark mode //
// ///////////////
@media screen and (prefers-color-scheme: dark) {
  body {
    background-color: #80B3FE;
    color: $white;
  }
  $grey_bg: #464646;
  .text-wrapper {
    color: $white;
    background-color: $grey_bg;
  }
  .octo-wrapper + .container{
    .text-wrapper{
      a{
        color: #47739A;
      }
    }
  }
  .container ul h3{
    text-shadow: 1px 1px 1px $white;
  }
}
